import styles from "./index/index.page.module.scss";
import { indexPage } from "grandus-lib/utils/fetches";
import EnhancedEcommerce from "utils/ecommerce";
import TagManager from "grandus-lib/utils/gtag";

import { Row, Col, Carousel, Button } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { get, map } from "lodash";

import Banner from "components/banner/Banner";
import ProductCard from "components/product/card/ProductCard";
import ProductDiscountCard from "components/product/card/ProductDiscountCard";
import BlogCard from "components/blog/card/BlogCard";

import Instagram from "components/instagram";
import Content from "grandus-lib/components-atomic/content/Content";
import MetaData from "grandus-lib/components-atomic/MetaData";
import Link from "next/link";
import { useEffect } from "react";
import {IS_XMAS_THEME} from "../constants/AppConstants";

const HomepageContent = ({ pages }) => {
  if (!pages) return null;

  return (
    <>
      {map(pages, (page, index) => {
        const { id, content, customJavascript, customCss } = page;
        return (
          <Content
            content={content}
            css={customCss}
            js={customJavascript}
            key={`static-page-${id}-${index}`}
          />
        );
      })}
    </>
  );
};

const Homepage = (props) => {
  const {
    meta = {},
    banners = [],
    banners01 = [],
    products = [],
    productsNew = [],
    productsSale = [],
    blogs = [],
    homepageStaticPageLocation1 = [],
    homepageStaticPageLocation2 = [],
    homepageStaticPageLocation3 = [],
    homepageStaticPageLocation4 = [],
    homepageStaticPageLocation5 = [],
  } = props;

  useEffect(() => {
    TagManager.push(
      EnhancedEcommerce.impressions(products, "homepage favourite products")
    );
    TagManager.push(
      EnhancedEcommerce.impressions(productsNew, "homepage new products")
    );
  }, []);

  meta.options = {
    image: {
      dimensions: "1200",
      width: 1200,
      height: -1,
    },
  };
  meta.photo = get(banners, "[0].photo", {});

  return (
    <section className={styles.wrapper}>
      <MetaData {...meta} />
      <main className={"container guttered"}>
        {/* BANNERS */}
        <div className={styles["homepage-banners-section"]}>
          <Row gutter={[{ xs: 0, sm: 10 }, 10]}>
            <Col xs={24} md={14}>
              <Carousel autoplay>
                {map(banners, (banner, index) => {
                  return (
                    <div key={`banners-${banner.id}-${index}`}>
                      <Banner banner={banner} imageSize={"708x354__cropped"} />
                    </div>
                  );
                })}
              </Carousel>
            </Col>

            <Col xs={24} md={10}>
              <div className={styles["side-banners"]}>
                {map(banners01, (banner, index) => {
                  return (
                    <div key={`banners-custom-${banner.id}-${index}`}>
                      <Banner
                        banner={banner}
                        imageSize={"510x175__cropped"}
                        options={{
                          className: styles.sidebanner,
                          titleClass: styles.title,
                          subtitleClass: styles.subtitle,
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row>
        </div>
      </main>

      <div className={`${styles.backgroundType1} ${IS_XMAS_THEME ? styles?.xmas : ""}`}>
        <main className={"container guttered"}>
          <h2>Cenové trháky</h2>
          <Row gutter={[{ xs: 0, sm: 10 }, 10]}>
            {map(productsSale, (product, index) => {
              return (
                <Col
                  xs={12}
                  sm={8}
                  md={6}
                  lg={4}
                  key={`products-sales-${product.id}-${index}`}
                >
                  <ProductDiscountCard {...product} />
                </Col>
              );
            })}
          </Row>
          <Row style={{ marginTop: "15px" }} justify="center">
            <Col>
              <Link
                href="/kategoria/[category]/[[...parameters]]"
                as="/kategoria/produkty/tag/trhak"
              >
                <Button type="primary" size="large" className={IS_XMAS_THEME ? "xmas-button" : ""}>
                  Zobraziť produkty <RightOutlined />
                </Button>
              </Link>
            </Col>
          </Row>
        </main>
      </div>

      <main className={"container guttered"}>
        {/* STATIC PAGES LOCATION 02 */}
        <HomepageContent pages={homepageStaticPageLocation2} />
      </main>

      <div className={`${styles.backgroundType2} ${IS_XMAS_THEME ? styles?.xmas : ""}`}>
        <main className={"container guttered"}>
          <h2>Vyberáme z našej ponuky</h2>
          <Row gutter={[{ xs: 0, sm: 10 }, 10]}>
            {map(productsNew, (product, index) => {
              return (
                <Col
                  xs={12}
                  sm={8}
                  md={6}
                  key={`products-news-${product.id}-${index}`}
                >
                  <ProductCard {...product} />
                </Col>
              );
            })}
          </Row>
        </main>
      </div>

      <main className={"container guttered"}>
        {/* STATIC PAGES LOCATION 03 */}
        <HomepageContent pages={homepageStaticPageLocation3} />
      </main>

      <div className={`${styles.backgroundType1} ${IS_XMAS_THEME ? styles?.xmas : ""}`}>
        <main className={"container guttered"}>
          <h2>Najobľúbenejšie boxy</h2>
          <div className={styles.boxes}>
            <Row gutter={[{ xs: 0, sm: 10 }, 10]}>
              {map(products, (product, index) => {
                return (
                  <Col xs={24} sm={8} key={`products-${product.id}-${index}`}>
                    <ProductCard {...product} options={{ hideDescription: true }} />
                  </Col>
                );
              })}
            </Row>
          </div>
        </main>
      </div>

      {/* STATIC PAGES LOCATION 01 */}
      <HomepageContent pages={homepageStaticPageLocation1} />

      <main className={"container guttered"}>
        {/* STATIC PAGES LOCATION 04 */}
        <HomepageContent pages={homepageStaticPageLocation4} />
      </main>

      {/* BLOGS */}
      <div className={styles.backgroundType2}>
        <main className={"container guttered"}>
          <h2>Posledné z našich blogov</h2>
          <Row gutter={[{ xs: 0, sm: 10 }, 10]} justify="space-around">
            {map(blogs, (blog, index) => {
              const { id } = blog;
              return (
                <Col xs={24} sm={8} key={`banners-${id}-${index}`}>
                  <BlogCard {...blog} />
                </Col>
              );
            })}
          </Row>
        </main>
      </div>

      <main>
        {/* STATIC PAGES LOCATION 05 */}
        {/* <Instagram /> */}
        {/* <HomepageContent pages={homepageStaticPageLocation5} /> */}
      </main>
    </section>
  );
};

export async function getServerSideProps(context) {
  return indexPage.serverSideProps(context);
}

export default Homepage;
