import { get } from "lodash";
import Image from "grandus-lib/components-atomic/image/Image";
import Link from "next/link";

import styles from "components/banner/Banner.module.scss";

const BannerContent = ({banner, imageSize, options}) => {
    return (
        <>
            <Image
                photo={banner.photo}
                size={imageSize}
                type={"jpg"}
            />
            {
                (get(banner, "title")) ? (
                    <div className={styles.overlay}>
                        <div className={`${styles.title} ${get(options, 'titleClass')}`}>
                            {banner.title}
                        </div>
                        {
                            (get(banner, "subtitle")) ? (
                                <div className={`${styles.subtitle} ${get(options, 'subtitleClass')}`}>
                                    {banner.subtitle}
                                </div>
                            ) : ("")
                        }
                        
                    </div>
                ) : ("")
            }
        </>
    )
}


const Banner = ({banner, imageSize, options}) => {
    const { url } = banner;
    return (
        <div className={`${styles.banner} ${get(options, 'className')}`}>
            {
                (!url) ? (
                    <BannerContent banner={banner} imageSize={imageSize} options={options} />
                ) : 
                    (url.startsWith("/")) ? (
                        <Link href={url}>
                            <a>
                                <BannerContent banner={banner} imageSize={imageSize} options={options} />
                            </a>
                        </Link>
                    ) : (
                        <a href={url}>
                            <BannerContent banner={banner} imageSize={imageSize} options={options} />
                        </a>
                    )
            }
        </div>
    )

};

export default Banner;
