import Link from "next/link";
import get from "lodash/get";
import toNumber from "lodash/toNumber";
import first from "lodash/first";
import EnhancedEcommerce from "utils/ecommerce";
import TagManager from "grandus-lib/utils/gtag";
import Image from "grandus-lib/components-atomic/image/Image";
import Price from "grandus-lib/components-atomic/price/Price";
import AddToCartButton from "components/cart/AddToCartButton";

import WishlistButton from "components/wishlistButton/WishlistButton";
import AddToShoppingListButton from "components/shoppingList/addButton/AddButton";

import styles from "components/product/card/ProductCard.module.scss";
import { useRouter } from "next/router";
import { addPopuptoUrl } from "components/product/modal";

const ProductDiscountCard = ({ options = {}, ...product }) => {
  const {
    id,
    name,
    urlTitle,
    finalPriceData,
    standardPriceData,
    photo,
    store,
    discount,
    isOrderable,
    storeStatus,
  } = product;
  const router = useRouter();
  const onClickHandler = (e) => {
    e.preventDefault();
    TagManager.push(
      EnhancedEcommerce.productClick(
        { ...product, ...{ position: get(options, "eecProduct.position") } },
        "category",
        {
          page: get(options, "eecProduct.page"),
          perPage: get(options, "eecProduct.perPage"),
        }
      ),
      () => {
        router.replace(
          addPopuptoUrl(window?.location?.href, urlTitle),
          undefined,
          {
            shallow: true,
            scroll: false,
          }
        );
      }
    );
  };

  const isAvailable = isOrderable;

  return (
    <div
      className={`${styles.productCardWrapper} ${styles.productCardDiscountWrapper}`}
    >
      <div
        className={`${styles.productCard} ${styles.discountCard} ${
          isOrderable ? "" : styles.nonAvailable
        }`}
      >
        <div className={styles.imageWrapper}>
          <Link href="/produkt/[id]" as={`/produkt/${urlTitle}`} scroll={false}>
            <a onClick={onClickHandler}>
              <Image
                photo={photo}
                size={"200x128__cropped"}
                type={"jpg"}
                className={styles.image}
              />
            </a>
          </Link>
          {discount ? <div className={styles.discount}>-{discount}</div> : ""}
          <div className={styles?.wishlistButton}>
            {/* <WishlistButton
                  productId={product?.id}
                  options={{ hideText: true }}
                /> */}
            <AddToShoppingListButton
              product={product}
              count={1}
              store={first(store)?.id}
              text=""
              dropdownArrowVisible={false}
              size="large"
              dropdownPosition="left"
            />
          </div>
        </div>
        <div className={styles.content}>
          <Link href="/produkt/[id]" as={`/produkt/${urlTitle}`} scroll={false}>
            <a onClick={onClickHandler}>
              <div className={styles.title}>{name}</div>
            </a>
          </Link>
          <Price
            priceData={finalPriceData}
            className={styles.price}
            options={{
              mainPriceClass: styles.mainPrice,
              withoutVatPriceClass: styles.withoutVat,
            }}
          />
          {toNumber(get(standardPriceData, "price")) >
          toNumber(get(finalPriceData, "price")) ? (
            <span className={styles?.strikethroughPrice}>
              {get(standardPriceData, "priceFormatted")}
            </span>
          ) : (
            <span>&nbsp;</span>
          )}
        </div>
        <div className={styles.content}>
          <div className={styles.buttons}>
            {isAvailable ? (
              <>
                <AddToCartButton
                  stores={store}
                  productId={id}
                  options={{ useToastr: true }}
                />
              </>
            ) : (
              <div className={styles.availabilityMessage}>
                <strong className={styles.alertText}>
                  Tovar nie je aktuálne dostupný.
                </strong>
                {get(
                  storeStatus,
                  "messageUnavailable",
                  "Pracujeme na jeho naskladnení."
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDiscountCard;
