import styles from "components/blog/card/BlogCard.module.scss";
import { Card, Typography } from "antd";

import dynamic from "next/dynamic";
import { ArrowRightOutlined } from "@ant-design/icons";
const Link = dynamic(() => import("next/link"));
const Image = dynamic(() =>
  import("grandus-lib/components-atomic/image/Image")
);

const { Paragraph } = Typography;

const BlogCard = ({
  title,
  urlTitle,
  perex,
  photo,
  communityCategory,
  publishTime,
}) => {
  return (
    <div className={styles.wrapper}>
      <Link href="/blog/[id]" as={`/blog/${urlTitle}`}>
        <a>
          <Card
            cover={
              <Image photo={photo} size={"285x170__cropped"} type={"jpg"} />
            }
          >
            <div className={styles.content}>
              <div className={styles.metadata}>
                <span>{communityCategory ? communityCategory.name : ""}</span>
              </div>
              <div className={styles.title}>{title}</div>
              <Paragraph className={styles.paragraph} ellipsis={{ rows: 3 }}><div dangerouslySetInnerHTML={{ __html: perex }} /></Paragraph>
            </div>
            <div className={styles?.readMore}>
              Čítať ďalej <ArrowRightOutlined />
            </div>
          </Card>
        </a>
      </Link>
    </div>
  );
};

export default BlogCard;
